import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://efe182aebbfb9953d76db448bf6dfd8e@o4507284184301568.ingest.us.sentry.io/4507284234960896",
  debug: false,
  environment: import.meta.env.PUBLIC_VERCEL_ENV,
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 1,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
});